import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import { useTransition, animated } from "react-spring"
import PropTypes from "prop-types"
import _ from "lodash"
import styled from "styled-components"
import useMediaQuery from "../Hooks/MatchMedia"
import { Logo } from "../../../static/assets/Logo"

const Hamburger = styled.div`
  background-color: #fff;
  width: 30px;
  height: 2px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 30px;
    height: 2px;
    left: 0;
    background-color: #fff;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`

const MenuItems = () => {
  return (
    <div className="nav-list">
      <a className="nav-item" href="/#about">
        ABOUT
      </a>
      <a className="nav-item" href="/#projects">
        Projects
      </a>
      <a className="nav-item" href="/#contact">
        CONTACT
      </a>
    </div>
  )
}

const NavBar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)
  const navbar = useRef()
  let mobile = useMediaQuery("(min-width: 1024px)")
  const [scrollPosY, prevScrollPosY] = useState(0)

  useEffect(() => {
    const scrollFunction = _.debounce(() => {
      let currentScrollPosY = window.scrollY
      if (scrollPosY > currentScrollPosY) {
        document.getElementById("navbar").style.top = "0"
      } else {
        document.getElementById("navbar").style.top = "-60px"
      }
      prevScrollPosY(currentScrollPosY)
    }, 20)

    window.addEventListener("scroll", () => {
      scrollFunction()
    })

    return () => {
      window.removeEventListener("scroll", scrollFunction)
    }
  }, [scrollPosY])

  const appear = useTransition(navbarOpen, {
    from: { right: "-50%", height: "0em" },
    enter: { height: "10em", right: "0%" },
    leave: { right: "-50%", height: "0em" },
  })

  return (
    <nav id="navbar">
      <div className="nav-bar">
        <div>
          <Link to="/#">
            <Logo />
          </Link>
        </div>
        {mobile ? (
          <MenuItems />
        ) : (
          <button
            aria-label="button"
            className="toggle"
            onClick={() => setNavbarOpen(!navbarOpen)}
            ref={navbar}
          >
            {navbarOpen ? <Hamburger open /> : <Hamburger />}
          </button>
        )}
      </div>
      {appear(
        (appear, item) =>
          item && (
            <animated.div
              className="mobile-menu"
              aria-label={"menu"}
              onClick={() => setNavbarOpen(!navbarOpen)}
              style={appear}
            >
              <MenuItems />
            </animated.div>
          )
      )}
    </nav>
  )
}

NavBar.propTypes = {
  navbarOpen: PropTypes.bool,
}

export default NavBar
