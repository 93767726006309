import React from "react"
import { Link } from "gatsby-plugin-modal-routing-3"

const Footer = () => {
  return (
    <footer>
      <Link to="/">© 2022 Land Design Studios Ltd</Link>
      <Link to="/cookie-policy" asModal>
        Cookie Policy
      </Link>
      <a href="/#about">About</a>
      <a href="/#projects">Projects</a>
      <a href="/#contact">Contact Us</a>
    </footer>
  )
}

export default Footer
